.title{align-items:center;
display:flex;
justify-content:center;
font-family:'Times New Roman', Times, serif;
font-weight: 500;
font-size:25px;
}
@media (min-width:990px){
    .custom{display:none;} 
      
}
.app-name{
    position:relative;
    margin-left:10px;
    bottom:12px;
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: 20px;
    
}
.details{
    font-size: 15px;
}
.data-charge{
    color:grey;
}
button{
padding: 10px 10px;
font-weight: 600;
background-color: rgba(99, 4, 253, 0.584);
border-color:transparent;
box-shadow:5px 5px white;
border-radius: 5px 5px;
color: azure;
}

@keyframes animate{
    0%,50%,100%{
      transform: translateY(0) ;
    }
    40%,60%{transform: translateY(5px) ;}
    }
p{font-size:12px;}
@keyframes typing {
    100%{
      left:100%;
      margin:0 -35px 0 35px;  
    }
    
}
@keyframes slide {
    100%{
      top:-80px;
    }
    
}
a{text-decoration: none;}